import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { baseURL } from "./urls";
import useAuthAction from "../custom-hooks/useAuthAction";

export function setAuthHeader(token = null) {
	if (token) {
		// setting the authorization header in a case where the auth JWT token was provided
		axiosInstance.defaults.headers.common[
			"Authorization"
		] = `Bearer ${token}`;
	} else {
		// deleting the authorization header that has already been set in a case where auth JWT token was not provided
		delete axiosInstance.defaults.headers.common["Authorization"];
	}
}

export const axiosInstance = axios.create({
	baseURL: baseURL
});

const formatError = (error) => {
	let err = error;
	if (!error?.response?.data?.message)
		err = {
			response: {
				data: {
					message: error.message
				}
			}
		};
	return err;
};

export const getSearchRequest = async (request) => {
	try {
		return (await axiosInstance.get(request.queryKey, request.data)).data;
	} catch (error) {
		formatError(error);
	}
};

export const getRequest = async (request) => {
	try {
		return (await axiosInstance.get(request.queryKey[0], request.data))
			.data;
	} catch (error) {
		throw formatError(error);
	}
};

const postRequest = (request) => axiosInstance.post(request.url, request.data);

const editRequest = (request) => axiosInstance.put(request.url, request.data);

const patchRequest = (request) =>
	axiosInstance.patch(request.url, request.data);

const putRequest = (request) => axiosInstance.put(request.url, request.data);

const getFileRequest = async (request) => {
	try {
		return await axiosInstance.get(request.queryKey[0], {
			responseType: "arraybuffer",
			headers: { "Content-Type": "blob" }
		});
	} catch (error) {
		formatError(error);
	}
};

const deleteRequest = (request) =>
	axiosInstance.delete(request.url, {
		data: request.data
	});

export const useReturnQueryOptions = (queryOptions) => {
	const { push } = useHistory();
	const { logout } = useAuthAction();
	return {
		onError: (error) => {
			if (
				error?.response?.status === 403 ||
				error?.response?.data?.message ===
					"Request failed with status code 403"
			)
				push({ pathname: "/no_access", state: { fromError: true } });

			if (
				error?.response?.status === 401 ||
				error?.response?.data?.message ===
					"Request failed with status code 401"
			) {
				logout();
			}
		},
		retry: (_, error) =>
			!(error?.response?.status === 400 || 403 || 401 || 500),
		...queryOptions
	};
};

/**
 * This is api hook for different http operations
 * Takes the following values
 * @param  data: {url, body}
 * @returns Object
 */
export const useApiGet = (key, queryOptions) =>
	useQuery(key, getRequest, useReturnQueryOptions(queryOptions));

export const useApiPost = (options) =>
	useMutation(postRequest, useReturnQueryOptions(options));

export const useApiEdit = (options) =>
	useMutation(editRequest, useReturnQueryOptions(options));

export const useApiPatch = (options) =>
	useMutation(patchRequest, useReturnQueryOptions(options));

export const useApiPut = (options) =>
	useMutation(putRequest, useReturnQueryOptions(options));

export const useApiBlob = (key, queryOptions = {}) =>
	useQuery(key, getFileRequest, useReturnQueryOptions(queryOptions));

export const useApiDelete = (data) =>
	useMutation(deleteRequest, useReturnQueryOptions(data));

// 	import axios from "axios";
// import { useMutation, useQuery } from "react-query";
// import { useHistory } from "react-router-dom";
// import { baseURL } from "./urls";
// import useAuthAction from "../custom-hooks/useAuthAction";
// import { REFRESH_TOKEN_HOLDER, TOKEN_HOLDER } from "../utils/constants";
// import { useCookies } from "react-cookie";
// import { useSelector } from "react-redux";

// export function setAuthHeader(token = null) {
// 	if (token) {
// 		// setting the authorization header in a case where the auth JWT token was provided
// 		axiosInstance.defaults.headers.common[
// 			"Authorization"
// 		] = `Bearer ${token}`;
// 	} else {
// 		// deleting the authorization header that has already been set in a case where auth JWT token was not provided
// 		delete axiosInstance.defaults.headers.common["Authorization"];
// 	}
// }

// export const axiosInstance = axios.create({
// 	baseURL: baseURL
// });

// const formatError = (error) => {
// 	let err = error;
// 	if (!error?.response?.data?.message)
// 		err = { response: { data: { message: error.message } } };
// 	throw err;
// };

// export const getSearchRequest = async (request) => {
// 	try {
// 		return (await axiosInstance.get(request.queryKey, request.data)).data;
// 	} catch (error) {
// 		formatError(error);
// 	}
// };

// export const getRequest = async (request) => {
// 	try {
// 		return (await axiosInstance.get(request.queryKey[0], request.data))
// 			.data;
// 	} catch (error) {
// 		formatError(error);
// 	}
// };

// const postRequest = (request) => axiosInstance.post(request.url, request.data);

// const editRequest = (request) => axiosInstance.put(request.url, request.data);

// const patchRequest = (request) =>
// 	axiosInstance.patch(request.url, request.data);

// const putRequest = (request) => axiosInstance.put(request.url, request.data);

// const getFileRequest = async (request) => {
// 	try {
// 		return await axiosInstance.get(request.queryKey[0], {
// 			responseType: "arraybuffer",
// 			headers: { "Content-Type": "blob" }
// 		});
// 	} catch (error) {
// 		formatError(error);
// 	}
// };

// const deleteRequest = (request) =>
// 	axiosInstance.delete(request.url, {
// 		data: request.data
// 	});

// export const useReturnQueryOptions = (queryOptions) => {
// 	const { push } = useHistory();
// 	const { logout } = useAuthAction();
// 	const { isImpersonating } = useSelector(
// 		(state) => state.impersonatorDetails
// 	);
// 	const setCookie = useCookies([TOKEN_HOLDER])[1];
// 	const [cookies] = useCookies([TOKEN_HOLDER, REFRESH_TOKEN_HOLDER]);
// 	return {
// 		onError: (error) => {
// 			if (
// 				error?.response?.status === 403 ||
// 				error?.response?.data?.message ===
// 					"Request failed with status code 403"
// 			)
// 				push({ pathname: "/no_access", state: { fromError: true } });
// 			if (
// 				error?.response?.status === 401 ||
// 				error?.response?.data?.message ===
// 					"Request failed with status code 401"
// 			) {
// 				if (isImpersonating) {
// 					logout();
// 				} else {
// 					refreshAccessToken(
// 						cookies[REFRESH_TOKEN_HOLDER],
// 						cookies[TOKEN_HOLDER]
// 					).then(({ newAccessToken, newRefreshToken }) => {
// 						console.log({ newAccessToken, newRefreshToken });
// 					});
// 				}
// 			}
// 		},
// 		retry: async (failureCount, error) => {
// 			if (error?.response?.status === 401 && failureCount <= 1) {
// 				const { newAccessToken, newRefreshToken } =
// 					await refreshAccessToken(
// 						cookies[REFRESH_TOKEN_HOLDER],
// 						cookies[TOKEN_HOLDER]
// 					);
// 				if (newAccessToken) {
// 					setCookie(TOKEN_HOLDER, newAccessToken, {
// 						path: "/"
// 					});
// 					setCookie(REFRESH_TOKEN_HOLDER, newRefreshToken, {
// 						path: "/"
// 					});
// 					setAuthHeader(newAccessToken); // Update the Authorization header
// 					return true; // Retry the request with the updated token
// 				}
// 			}
// 			return false; // Don't retry for other errors or after retrying once
// 		},
// 		...queryOptions
// 	};
// };

// /**
//  * This is api hook for different http operations
//  * Takes the following values
//  * @param  data: {url, body}
//  * @returns Object
//  */
// export const useApiGet = (key, queryOptions) =>
// 	useQuery(key, getRequest, useReturnQueryOptions(queryOptions));

// export const useApiPost = (options) =>
// 	useMutation(postRequest, useReturnQueryOptions(options));

// export const useApiEdit = (options) =>
// 	useMutation(editRequest, useReturnQueryOptions(options));

// export const useApiPatch = (options) =>
// 	useMutation(patchRequest, useReturnQueryOptions(options));

// export const useApiPut = (options) =>
// 	useMutation(putRequest, useReturnQueryOptions(options));

// export const useApiBlob = (key, queryOptions = {}) =>
// 	useQuery(key, getFileRequest, useReturnQueryOptions(queryOptions));

// export const useApiDelete = (data) =>
// 	useMutation(deleteRequest, useReturnQueryOptions(data));

// const refreshAccessToken = async (refreshToken, accessToken) => {
// 	const url = `${baseURL}/Authentication/refresh-access-token`; // Replace this with your token endpoint
// 	try {
// 		const response = await axios.post(url, {
// 			accessToken,
// 			refreshToken
// 		});

// 		if (response.status === 200) {
// 			const access_token = response.data?.data?.jwtToken?.token;
// 			const refresh_token = response.data?.data?.refreshToken;
// 			return {
// 				newAccessToken: access_token,
// 				newRefreshToken: refresh_token
// 			};
// 		} else {
// 			console.error("Failed to refresh access token.");
// 			return { newAccessToken: null, newRefreshToken: null };
// 		}
// 	} catch (error) {
// 		return { newAccessToken: null, newRefreshToken: null };
// 	}
// };
