export const generateUrlParams = (obj) => {
	let generatedUrl = ``;
	const arrayOfObjectKeys = Object.keys(obj);
	arrayOfObjectKeys.forEach((key) => {
		if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
			generatedUrl += `${key}=${obj[key]}&`;
		}
	});
	generatedUrl = generatedUrl.slice(0, -1);
	return generatedUrl;
};
