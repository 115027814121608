import React, { forwardRef } from "react";
import Select from "react-select";
import { ValidationText } from "..";

export const selectStyles = ({ isError }) => ({
	input: (styles) => ({
		...styles,
		"&:not(.aui-no-focusvisible) :focus-visible": {
			boxShadow: "none",
			border: "1px solid white"
		}
	}),

	menu: (provided) => ({
		...provided,
		overflowY: "auto",
		scrollbarColor: "transparent",
		scrollbarWidth: "thin",
		"&::-webkit-scrollbar": {
			width: "7px"
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "transparent !important",
			borderRadius: "2.5px",
			height: "50px"
		},
		"&::-webkit-scrollbar-track": {
			background: "transparent !important",
			borderBottomRightRadius: "16px"
		},
		"&::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track": {
			background: "transparent"
		}
	}),
	control: (styles, { isDisabled, isFocused }) => ({
		...styles,
		borderRadius: "4px",
		border: isFocused
			? "2px solid var(--primary)"
			: isError
			? "1px solid red"
			: "1px solid #dfe1e6",
		boxShadow: isFocused ? 0 : 0,
		"&:hover": {
			border: isFocused
				? "2px solid var(--primary)"
				: isError
				? "1px solid red"
				: "1px solid #dfe1e6"
		},
		minHeight: "40px",
		color: isDisabled ? "3" : "#97a0af",
		backgroundColor: isDisabled ? "#f4f5f7" : "var(--dark-bg-text-color)"
	}),
	placeholder: (styles) => ({
		...styles,
		fontSize: "14px",
		fontWeight: 450,
		lineHeight: "20px",
		color: "#97a0af"
	}),
	valueContainer: (styles) => ({
		...styles,
		borderLeft: "none",
		fontSize: "14px",
		minHeight: "40px"
	}),
	indicatorSeparator: (styles) => ({
		...styles,
		display: "none",
		fontSize: "14px"
	}),
	dropdownIndicator: (styles) => ({
		...styles,
		color: "#42526E",
		fontSize: "14px"
	}),
	autosizeInput: (styles) => ({
		...styles,
		"&:not(.aui-no-focusvisible) :focus-visible": { boxShadow: "none" }
	})
});

export const SMSelect = forwardRef(
	(
		{
			options = [
				{ label: "Un-appraised", value: "Un-appraised" },
				{ label: "In-progress", value: "In-progress" },
				{ label: "completed", value: "completed" }
			],
			onChange = () => {},
			selectWidth = "100%",
			placeholder = "Hello",
			disabled,
			loading,
			defaultInputValue,
			value,
			searchable,
			id,
			isError = false,
			errorText = "",
			...field
		},
		ref
	) => {
		const handleChange = async (value) => {
			const awaitedValue = await value;
			onChange(awaitedValue);
		};

		return (
			<div className="d-flex">
				<div style={{ width: selectWidth }}>
					<Select
						options={options}
						placeholder={placeholder}
						onChange={handleChange}
						styles={selectStyles({ isError })}
						isDisabled={disabled}
						isLoading={loading}
						defaultInputValue={defaultInputValue}
						value={value}
						isSearchable={searchable}
						id={id}
						ref={ref}
						{...field}
					/>
					<div>
						{errorText.length > 0 && (
							<ValidationText
								status={"error"}
								message={errorText}
							/>
						)}
					</div>
				</div>
			</div>
		);
	}
);
